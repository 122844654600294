import React, { useEffect, useState } from "react";
import { Script } from "gatsby";

const GoogleReview = () => {
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })

        setTimeout(() => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        }, 5000);

    }, [])
     return (
        <div className="google-review-wrapper d-md-flex align-items-center">
            {renderComponent &&
                 <Script src="https://apps.elfsight.com/p/platform.js" strategy="idle" />
            }
            <div className="elfsight-app-bca3669b-c500-48ea-a6c4-c5e32338bce2" data-elfsight-app-lazy></div>
        </div>
    )

}

export default GoogleReview
